.container {
  min-height: 50vh;
  background-color: #fff;
  padding: 1rem;
  border-top-left-radius: var(--section-border-radius);
  border-top-right-radius: var(--section-border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.blockContainer {
  background-color: #023047;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  box-shadow: 0 5px var(--secondary-color);
  min-width: 200px;
  min-height: 200px;
  border-radius: 12px;
  margin: 1rem;
  cursor: pointer;
  transition: 0.2s;
}

.blockContainer:hover {
  transform: translateY(-10px);
}

.blockContainer:active {
  box-shadow: none;
  transform: translateY(5px);
}

.blockContainer img {
  width: 100px;
}

.blockContainer p {
  margin-top: 1rem;
}
