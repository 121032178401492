.servicesContainer {
  padding: 2rem;
  background-color: #fff;
  border-top-left-radius: var(--section-border-radius);
  border-top-right-radius: var(--section-border-radius);
  color: var(--primary-color);
  min-height: 90vh;
  text-align: justify;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  cursor: default;
}

.servicesContainer > * {
  margin: 1rem 0;
}

.serviceContainer {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 1rem;
  max-width: 300px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.2s ease-in-out;
}

.serviceContainer:hover {
  transform: translateY(-10px);
}

.serviceContainer > * {
  margin: 0.5rem 0;
}

.serviceContainer img {
  max-width: 200px;
  max-height: 200px;
  border-radius: 12px;
}
