.header {
  background-color: var(--primary-color);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  text-align: center;
  min-height: 50vh;
}

.header img {
  width: 200px;
  margin-top: 2rem;
}

.header h1 {
  font-weight: 400;
  margin-top: 2rem;
}

.header h2 {
  font-weight: 300;
  margin-top: 0.5rem;
}

.btn {
  padding: 12px 24px;
  border: 0;
  background-color: #fff;
  color: var(--primary-color);
  border-radius: 12px;
  font-size: 20px;
  margin-top: 2rem;
  border: 2px solid #fff;
  transition: 0.2s;
  cursor: pointer;
}

.btn:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.slider {
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 100%;
}

.slide {
  padding: 12px 24px;
  background-color: #fff;
  color: var(--primary-color);
  border-radius: 12px;
  font-size: 20px;
}
