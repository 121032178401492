.container {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: repeat(3, min-content);
  align-content: center;
  justify-items: center;
  gap: 1rem;
  color: #fff;
  text-align: center;
}
