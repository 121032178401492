.topSection {
  padding: 2rem;
  color: var(--primary-color);
  background-color: #fff;
  border-top-left-radius: var(--section-border-radius);
  border-top-right-radius: var(--section-border-radius);
  min-height: 20vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.topSection h1 {
  max-width: 400px;
}

.topSection i {
  width: 28px;
  fill: var(--primary-color);
}

.iconsContainer {
  display: grid;
  grid-template-columns: min-content min-content;
  gap: 4rem;
  justify-items: center;
  margin-top: 1rem;
}

.iconsContainer a {
  position: relative;
}

.iconsContainer a i,
.iconsContainer a svg {
  transition: 0.2s;
}

.iconsContainer a:hover i,
.iconsContainer a:hover svg {
  transform: scale(1.2);
}

.tooltip {
  visibility: hidden;
  width: 120px;
  top: 100%;
  left: 50%;
  margin-top: 0.5rem;
  margin-left: -60px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.iconsContainer a:hover .tooltip {
  visibility: visible;
}

.bottomSection {
  min-height: 70vh;
  padding: 2rem;
  width: 100%;
  background-color: #fff;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.bottomSection h1 {
  text-align: center;
}

.bottomSection p {
  margin-bottom: 2rem;
  text-align: center;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  min-width: 250px;
}

.form *:not(span) {
  display: block;
}

.formContainer input,
.formContainer textarea {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.2rem;
}

.formContainer input:focus,
.formContainer textarea:focus {
  outline: none;
  border: 2px solid var(--secondary-color);
}

.formContainer .formContainer textarea {
  resize: vertical;
}

.sendBtn {
  padding: 12px 24px;
  border: 0;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 12px;
  font-size: 20px;
  border: 2px solid var(--primary-color);
  transition: 0.2s;
  cursor: pointer;
}

.sendBtn:hover {
  background-color: #fff;
  color: var(--primary-color);
}

.gridSpanTwo {
  grid-column: span 2;
}

.red {
  color: red;
}

@media (max-width: 600px) {
  .formContainer {
    grid-column: span 2;
  }
}
